<template>
  <v-row class="support-bar d-flex justify-center align-center">
    <div class="d-flex align-center flex-column flex-md-row py-6 py-md-11">
      <!-- immagine desktop -->
      <v-img src="/footer/help1.svg')" class="d-none d-md-flex mr-8"></v-img>
      <!-- immagine mobile -->
      <v-img
        src="require('/help1-small.svg')"
        class="small d-flex d-md-none mb-8"
      ></v-img>
      <div class="d-flex flex-column justify-center align-center">
        <h2>{{ $t("footer.support.title") }}</h2>
        <h3>{{ $t("footer.support.text") }}</h3>
        <v-btn
          :disabled="!checked"
          text
          color="primary"
          to="/page/contatti"
          class="mt-2"
          >{{ $t("footer.support.button") }}</v-btn
        >
        <!-- TODO: pulsante attivato se checked, da confermare -->
        <v-checkbox
          dark
          class="legal-agreement legal-agreement-white mt-5 px-8 px-md-0"
          hide-details
          v-model="checked"
        >
          <template v-slot:label>
            <i18n path="footer.support.privacy" tag="span">
              <template v-slot:link
                ><router-link
                  :to="{
                    name: 'Page',
                    params: { pageName: 'privacy-policy' }
                  }"
                >
                  {{ $t("footer.support.privacyLink") }}
                </router-link>
              </template>
            </i18n>
          </template>
        </v-checkbox>
      </div>
    </div>
  </v-row>
</template>

<style scoped lang="scss">
.support-bar {
  background-color: var(--v-primary-lighten1);
  position: relative;
  bottom: 14px;
  h2 {
    font-size: 22px;
    line-height: 33px;
    font-weight: 700;
    color: $white;
  }
  h3 {
    font-size: 18px;
    line-height: 33px;
    color: $white;
  }
  .v-btn {
    background: $white;
    font-size: 12px;
    font-weight: 700;
  }
  .v-image {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      &.small {
        height: 44px;
        width: 44px;
      }
    }
  }

  .privacy {
    .v-input__slot {
      background: transparent !important;
    }
    .v-label {
      span {
        color: $white;
        font-size: 13px;
      }
      a {
        font-size: 13px;
        color: inherit !important;
        text-decoration: underline;
      }
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      checked: false
    };
  },
  methods: {
    goToPrivacy() {
      window.open("/page/privacy-policy", "_blank");
    }
  }
};
</script>
