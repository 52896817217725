<template>
  <v-container fluid class="pa-0">
    <v-container
      fluid
      class="email-verification text-center text-md-left align-center align-md-start"
    >
      <h2 v-if="!verified">
        {{ $t("register.emailVerification.notVerified.title") }}
      </h2>
      <h2 v-else>{{ $t("register.emailVerification.verified.title") }}</h2>

      <h4 v-if="!verified" class="mb-10">
        {{ $t("register.emailVerification.notVerified.text") }}
      </h4>
      <h4 v-else class="mb-10">
        {{ $t("register.emailVerification.verified.text") }}
      </h4>

      <v-row v-if="!verified" no-gutters class="btns">
        <v-col cols="12" lg="6">
          <h4 class="not-received mb-2">
            {{ $t("register.emailVerification.notReceived") }}
          </h4>
          <v-row
            no-gutters
            class="mt-3 mt-md-0 d-flex justify-center justify-md-start"
          >
            <v-btn
              depressed
              color="primary"
              @click="resend()"
              :disabled="sentEmail"
            >
              {{ $t("register.emailVerification.resend") }}
            </v-btn>
            <h5 v-if="sentEmail" class="ml-5">
              {{ $t("register.emailVerification.sentEmail") }}
            </h5>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-else
        no-gutters
        class="btns mt-3 mt-sm-0 d-flex justify-center justify-lg-start"
      >
        <v-btn depressed color="primary btn-next" @click="next()">
          {{ $t("register.emailVerification.next") }}
        </v-btn>
      </v-row>
    </v-container>
    <SupportBar />
  </v-container>
</template>
<style scoped lang="scss">
.email-verification {
  padding: 63px 177px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 40px 32px;
  }
  .btns {
    margin-bottom: 168px;
    .not-received {
      margin-top: 84px;
    }
  }
}
</style>
<script>
import SupportBar from "@/components/registration/SupportBar.vue";

import loginMixins from "~/mixins/login";

import { mapState, mapGetters } from "vuex";

export default {
  mixins: [loginMixins],
  components: { SupportBar },
  props: ["verified", "email"],
  data() {
    return {
      sentEmail: false
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  methods: {
    resend() {
      this.sentEmail = true;
    },
    nextStep() {
      this.$emit("next", {
        step: 4,
        verified: false,
        user: this.cart.user,
        addressId: this.cart.shippingAddress.addressId
      });
    },
    async next() {
      let vm = this;
      if (vm.isAuthenticated) {
        this.nextStep();
      } else {
        if (await this.doLogin()) {
          this.nextStep();
        }
      }
    }
  }
};
</script>
