<template>
  <v-container
    class="registration-completed pa-0 text-center text-md-left align-center align-md-start"
    fluid
  >
    <!-- se il flusso di registrazione è per utente con carta (solo aggiornamento dati) -->
    <div class="tab-card">
      <h2 v-if="!pending">{{ $t("register.registrationCompleted.title") }}</h2>
      <!-- se il flusso di registrazione è per un utente nuovo senza carta -->
      <div v-else @click="clicked">
        <h2
          v-html="$t('register.registrationCompleted.noCard.titlePending')"
        ></h2>
        <div
          v-html="$t('register.registrationCompleted.noCard.subtitlePending')"
        ></div>
      </div>
    </div>
    <!-- <v-row no-gutters class="pt-16 justify-center justify-sm-start">
      <v-btn color="primary" :to="{ name: 'Home' }">
        {{ $t("register.registrationCompleted.goToHome") }}
      </v-btn>
    </v-row> -->
    <NewsletterHorizontal />
  </v-container>
</template>

<script>
import NewsletterHorizontal from "@/components/cards/NewsletterHorizontal.vue";
import clickHandler from "~/mixins/clickHandler";

export default {
  mixins: [clickHandler],
  props: ["pending"],
  components: {
    NewsletterHorizontal
  }
};
</script>
